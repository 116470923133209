<template>
  <div class="w-full ">
    <h6>Per iniziare, cosa vuoi vendere?</h6>
    <form @submit.prevent="submitForm"></form>
    <div class="">
      <fieldset id="sectType" class="mt-6">
        <div class="grid grid-cols-2  gap-4">
          <div
            v-for="(sect, s) in storeCategories"
            :key="s"
            class="relative flex items-baseline"
            @click="s_storeType = sect.value"
          >
            <input type="radio" v-model="s_storeType" :value="sect.value" />
            <label class="h-6">{{ sect.label }}</label>
          </div>
        </div>
      </fieldset>
      <!-- <radio :options="sections" class="py-2 text-gray-500"> </radio> -->
    </div>
    <h6 class="font-bold mt-12 mb-6 text-gray-600">
      Come si chiama il tuo negozio?
    </h6>
    <input
      type="text"
      class="w-full"
      placeholder="Nome dello store"
      v-model="s_storeName"
      required
      pattern=""
    />
    <div class="flex items-baseline mt-12">
      <router-link to="/" class="text-secondary-500 w-full text-center">
        <i class="mdi mdi-arrow-left"></i>
        Torna indietro
      </router-link>
      <button
        class="btn btn-cta w-full text-center"
        :class="s_storeName == '' ? ' cursor-not-allowed' : null"
        @click="s_storeName == '' ? false : $emit('nextstep')"
        :disabled="s_storeName == ''"
      >
        Continua
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      sections: [
        { name: "Cibo", value: "food" },
        { name: "Fitness", value: "fitness" },
        { name: "Musica", value: "music" },
        { name: "Abbigliamento & Moda", value: "fashion" },
        { name: "Arte & Cultura", value: "art" },
        { name: "Consulenza", value: "consueling" },
        { name: "Altro", value: "other" }
      ]
    };
  },
  computed: {
    ...mapState("createstore", ["storeName", "storeType", "storeCategories"]),
    s_storeName: {
      get() {
        return this.storeName;
      },
      set(v) {
        this.SET_STORENAME(v);
      }
    },
    s_storeType: {
      get() {
        return this.storeType;
      },
      set(v) {
        this.SET_STORETYPE(v);
      }
    }
  },
  methods: {
    ...mapMutations("createstore", ["SET_STORENAME", "SET_STORETYPE"]),
    submitForm() {
      if (!this.s_storeName) {
        return false;
      }
    }
  }
};
</script>

<style></style>
